import fetch from 'isomorphic-unfetch'
import jwtDecode from 'jwt-decode'
import { getTokenFromCookie, getTokenFromLocalStorage } from '../utils/auth'
import config from '../config'
import { setToken, invalidToken } from '../utils/auth'
import axios from 'axios'

function queryParams(params) {
    return Object.keys(params)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
}

export default class Api {

    static axios
    static refreshing = false
    static token

    static setCTX(ctx) {
        Api.ctx = ctx;
    }

    static async fetch(url, props = {}) {
        Api.token = process.browser ? getTokenFromLocalStorage() : getTokenFromCookie(Api.ctx.req)
        if (!Api.axios) {
            console.log("refreshing axios")
            Api.axios = axios.create({
                baseURL: process.browser ? config.api : config.localApi,
                headers: {
                    "Accept": 'application/json',
                    "content-type": 'application/json'
                }
            })
            Api.axios.interceptors.response.use(function (response) {
                return response.data
            }, function (error) {
                if (error.response && error.response.status < 500) {
                    invalidToken(Api.ctx)
                }
                return Promise.reject(error);
            });
        }

        if (Api.token && process.browser) {
            const jwt = jwtDecode(Api.token)
            const current_time = Date.now() / 1000;
            if (jwt.exp < current_time) {
                invalidToken(Api.ctx)
            } else if (current_time - jwt.iat > 86400 && !Api.refreshing) {
                Api.refreshing = new Promise(async (resolve, reject) => {
                    console.log("refreshing token url: ", url, Api.token)
                    let data;
                    try {
                        data = await Api.axios.get("/refresh_token", {
                            headers: {
                                'x-access-token': Api.token
                            }
                        })
                    } catch (error) {
                        console.log(error)
                        Api.refreshing = false
                    }
                    if (data && data.token) {
                        setToken(data.token)
                        Api.token = data.token
                    }
                    console.log("resolving token", url, Api.token)
                    resolve(Api.token)
                })
                await Api.refreshing
                Api.refreshing = false
            } else if (Api.refreshing) {
                console.log("awaiting", url)
                await Api.refreshing
                console.log("done waiting", url)
            } else
                console.log("didnt wait", url)
        }
        if (props.queryParams) {
            url += (url.indexOf('?') === -1 ? '?' : '&') + queryParams(props.queryParams);
            delete props.queryParams;
        }
        props.data = props.body
        console.log("returning", url, Api.token)
        return Api.axios.request(url, {
            headers: {
                ...Api.token ? { 'x-access-token': Api.token } : null
            },
            ...props
        })
    }

    static post(url, props = {}) {
        props.method = "post";
        return Api.fetch(url, props)
    }

    static get(url, props = {}) {
        props.method = "get";
        return Api.fetch(url, props)
    }

    static delete(url, props = {}) {
        props.method = "delete";
        return Api.fetch(url, props)
    }
}