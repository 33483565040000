import jwtDecode from 'jwt-decode'
import Cookie from 'js-cookie'
import Router from 'next/router'
import api from '../utils/api'

let hostname
if(process.browser){
  hostname = window.location.hostname
}

export const setToken = (token) => {
  if (!process.browser) {
    return
  }
  console.log("setting token")
  Cookie.set('jwt', token, { domain: hostname, expires: 365 })
  window.localStorage.setItem('login', Date.now())
}

export const setTempToken = (token) => {
  if (!process.browser) {
    return
  }
  console.log("setting token")
  Cookie.set('tempToken', token, { domain: hostname })
}

export const getTempToken = () => {
  return Cookie.getJSON("tempToken")
}

export const unsetTempToken = () => {
  return Cookie.remove('tempToken', { domain: hostname })
}

export const getTokenFromCookie = (req, startsWith='jwt=') => {
  if (!req.headers.cookie) {
    return undefined
  }
  const jwtCookie = req.headers.cookie.split(';').find(c => c.trim().startsWith(startsWith))
  if (!jwtCookie) {
    return undefined
  }
  const jwt = jwtCookie.split('=')[1]
  return jwt
}

export const getTokenFromLocalStorage = () => {
  const jwt = Cookie.get("jwt")
  return jwt
}

export const unsetToken = () => {
  console.log("unsetting token", hostname, Cookie)
  api.token = null
  if (!process.browser) {
    return
  }
  Cookie.remove('jwt')
  Cookie.remove('jwt', { domain: hostname })
  console.log("unsetted token", hostname, document.cookie)
  window.localStorage.setItem('logout', Date.now())
}

export const logout = async (apolloClient) => {
  api.get('/logout')
  unsetToken()
  const newState = { ...history.state, time: Date.now() }
  history.pushState(newState, null, location.href)
  await Router.replace('/welcomePage', "/")
  apolloClient.resetStore()
}

export const invalidToken = (ctx) => {
  console.log("invalidating token")
  if (!process.browser && ctx) {
    console.log("redirecting auth")
    ctx.res.writeHead(302, { Location: "/logout" })
    ctx.res.end()
  } else {
    unsetToken()
    const newState = { ...history.state, time: Date.now() }
    history.pushState(newState, null, location.href)
    Router.replace('/welcomePage', "/")
  }
}
